<template>
  <div>
    <div class="heading-dark">{{ fields.title }}</div>
    <div class="title-sub-copy">
      <RichText :document="fields.titleSubCopy"></RichText>
    </div>
    <div class="available-text">
      Aliv Fibr is available only for New Providence customers.<br>
      View service availability in your area – explore the map <a href="/map.pdf" target="_blank">here</a>.
    </div>

    <div class="available-text">
      To view a list of our plans and services, please click <a href="/bundles.pdf" target="_blank">here</a>.
    </div>
    <div class="link" @click="navigateToBundles">
      <RichText :document="fields.cta"></RichText>
    </div>
    <div class="row">
      <div v-for="bundle in bundleOffers" :key="bundle" class="col-md-4">
        <FibrBundleCard :fields="bundle"> </FibrBundleCard>
      </div>
    </div>
    <div class="terms">
      <RichText :document="fields.tcs"></RichText>
    </div>
  </div>
</template>

<script>
import { getBundleByEntryId } from "@/util/bundlesUtil.js";
import router from "@/router";
import entryId from "@/config/entryid.json";
import FibrBundleCard from "./FibrBundleCard.vue";
import RichText from "@/components/common/RichText.vue";

export default {
  name: "FibrBundlesContainer",
  inject: ["$contentful"],
  components: {
    FibrBundleCard,
    RichText,
  },
  data() {
    return {
      bundleOffers: [],
      fields: {},
    };
  },
  created() {
    this.$contentful.getEntry(entryId.fibrBundles.main).then(async (val) => {
      this.fields = val.fields;
      val.fields.bundleSelection.forEach(async (item) => {
        this.bundleOffers.push(await getBundleByEntryId(item.sys.id));
      });
    });
  },
  methods: {
    navigateToBundles() {
      router.push({ name: "all-bundles" });
    },
  },
};
</script>

<style scoped lang="scss">
.heading-dark {
  margin-bottom: 16px;
}

.title-sub-copy {
  color: #4f4f4f;
  text-align: center;
  font-size: $font-s;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
}

.link {
  font-style: normal;
  font-weight: 700;
  font-size: $font-xs;
  line-height: 18px;
  text-align: center;
  color: #9b51e0;
  cursor: pointer;
  margin-bottom: 40px;
}

.terms {
  font-style: normal;
  font-weight: 450;
  font-size: $font-xxs;
  line-height: 20px;
  text-align: center;
  color: #333333;
  margin-top: 20px;
}

.available-text {
  color: #4f4f4f;
  text-align: center;
  font-size: $font-s;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.available-text a {
  color: black; /* Link color in black */
  font-weight: bold;
  text-decoration: underline;
}
.available-text a:hover {
  color: gray; /* Optional hover effect */
}
</style>
