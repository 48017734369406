<template>
  <FibrProductBanner
    :title="banner.title"
    :description="banner.description"
    :imageUrl="banner.imageUrl"
  ></FibrProductBanner>

  <div class="horizontal-page-margin vertical-page-margin">
    <!-- Features Section -->
    <div class="section-margin">
      <div class="row">
        <div v-for="feature in features" :key="feature.id" class="col-md-4">
          <FeatureDescription
            :title="feature.title"
            :description="feature.description"
          ></FeatureDescription>
        </div>
      </div>
    </div>

    <!-- Secondary Feature Section -->
    <SecondaryFeature
      class="section-margin"
      :title="secondaryFeature.title"
      :description="secondaryFeature.description"
      :imageUrl="secondaryFeature.imageUrl"
    ></SecondaryFeature>

    <!-- Addons Section -->
    <FeatureDescription
      class="section-margin"
      :title="addons.title"
      :description="addons.description"
    ></FeatureDescription>

    <!-- Image With Text Section -->
    <ImageWithText
      class="section-margin-small"
      :leftAlignImage="false"
      :imageUrl="fibrAddon1.imageUrl"
    >
      <div class="fibr-addon-title">{{ fibrAddon1.title }}</div>
      <RichText :document="fibrAddon1.document"></RichText>
    </ImageWithText>

    <ImageWithText class="section-margin" :imageUrl="fibrAddon2.imageUrl">
      <div class="fibr-addon-title">{{ fibrAddon2.title }}</div>
      <RichText :document="fibrAddon2.document"></RichText>
    </ImageWithText>

    <!-- Speed Feature Section -->
    <FeatureDescription
      class="section-margin"
      :title="speed.title"
      :description="speed.description"
    ></FeatureDescription>
    <div class="available-text">
      Aliv Fibr is available only for New Providence customers.<br>
      View service availability in your area – explore the map <a href="/map.pdf" target="_blank">here</a>.
    </div>

    <div class="available-text">
      To view a list of our plans and services, please click <a href="/bundles.pdf" target="_blank">here</a>.
    </div>

    <!-- Bundles Section -->
    <div
      class="justify-content-center align-items-start gap-4 px-auto my-4"
      :style="{
        display: 'flex',
        flexWrap: 'wrap',
        transition: 'height 800ms ease-in-out',
      }"
    >
      <div
        v-for="bundle in bundles"
        :key="bundle.name"
        :class="{ 'bundle-card-expanded': bundle.isOpen }"
        class="px-3 shadow-lg py-3 rounded-3 bundle-card"
      >
        <h4>{{ bundle.name.split("- ")[1] }}</h4>
        <p>{{ bundle.description }}</p>
        <p
          :style="{ color: '#635890', fontSize: '30px', marginBottom: '0' }"
          class="fw-bolder"
        >
          {{ bundle.price }}
        </p>
        <span
          :style="{ color: '#635890', marginBottom: '8px', fontSize: '15px' }"
          >{{ bundle.vat }}</span
        ><br />
        <button
          @click="() => cardClicked(bundle)"
          type="button"
          :style="{
            marginBottom: '9px',
            marginTop: '9px',
            backgroundColor: '#635890',
            border: 'none',
            color: '#fff',
            padding: '8px 0px',
            borderRadius: '15px',
            fontWeight: '700',
            width: '100%',
          }"
        >
          Get Started
        </button>
        <div v-show="bundle.isOpen" class="bundle-details">
          <BundleDetailsLong
            class="details"
            :bundleId="bundle.id"
          ></BundleDetailsLong>
        </div>
        <button
          @click="toggleDetails(bundle)"
          type="button"
          class="toggle-button"
        >
          {{ bundle.isOpen ? "See less" : "View details" }} <br />
          <img
            class="view-details-arrow"
            :class="{ 'view-details-arrow-open': bundle.isOpen }"
            :src="require('@/assets/icons/purple_arrow.svg')"
          />
        </button>
      </div>
    </div>

    <!-- Internet Plans Section -->
    <div
      class="flex link-style my-5"
      style="
        width: 100% !important;
        justify-content: center;
        align-items: center;
        display: flex;
      "
    >
      <a
        :href="viewAll"
        class="text-white py-2 px-4 rounded-4 text-decoration-none shadow-lg"
        style="background-color: #635890; font-weight: 900"
        >More Internet Plans</a
      >
    </div>

    <!-- Testimonials Carousel -->
    <div class="section-margin">
      <div
        v-for="(testimonial, index) in testimonials.filter((t) => t.isHeader)"
        :key="index"
        :style="{
          border: '1px solid #cbd5e1',
          padding: '.1rem 6rem 2rem 6rem',
          margin: '2rem 0 2rem 0',
          position: 'relative',
          borderRadius: '1rem',
          backgroundColor: 'white',
          boxShadow: '1px 1px 10px #cbd5e1',
        }"
        style="@media (max-width: 900px) {padding: 0.1rem 0.2rem 2rem 0.2rem}"
      >
        <div
          class="testimonial-rating"
          :style="{
            transform: 'translateY(-30px)',
          }"
        >
          <img
            :src="testimonial.avatarUrl"
            alt="avatar"
            :style="{
              width: '60px',
              height: '60px',
              borderRadius: '50%',
              objectFit: 'cover',
              margin: '0 5px',
              border: '1px solid #cbd5ff',
            }"
          />
        </div>
        <h4 class="text-md-center">{{ testimonial.title }}</h4>
        <p class="text-sm-center" style="color: gray">
          <em>{{ testimonial.occupation }}</em>
        </p>
        <p class="position-relative text-sm-center">
          {{ testimonial.insights }}
          <img
            src="/quote.png"
            alt="quote"
            :style="{
              width: '80px',
              height: '80px',
              position: 'absolute',
              top: '-40px',
              left: '-80px',
              transform: 'translateX(50%)',
              transformOrigin: 'right',
              opacity: '0.2',
              transition: 'opacity 0.3s ease',
            }"
          />
        </p>
        <div class="testimonial-rating">
          <img
            v-for="star in testimonial.rating"
            :key="star"
            :src="getStarImagePath()"
            alt="Star"
            class="star-image"
          />
        </div>
      </div>
      <div class="carousel-container">
        <Carousel
          ref="testimonialCarousel"
          :items-to-show="3"
          :wrap-around="true"
          :paginationEnabled="false"
          :autoplay="false"
          :breakpoints="{
            // For desktop screens, show 3 items
            '1200': { itemsToShow: 3 },
            // For tablet and smaller screens, show 1 item
            '768': { itemsToShow: 1 },
            // For mobile screens, show 1 item
            '0': { itemsToShow: 1 },
          }"
          @slideChange="handleSlideChange"
        >
          <!-- Testimonials Slides -->
          <Slide v-for="(testimonial, index) in testimonials" :key="index">
            <div class="testimonial-slide text-center">
              <h4 class="testimonial-title">{{ testimonial.title }}</h4>
              <p class="testimonial-occupation">
                <em>{{ testimonial.occupation }}</em>
              </p>
              <p class="testimonial-insights position-relative">
                {{ testimonial.insights }}
                <img
                  src="/quote.png"
                  alt="quote"
                  :style="{
                    width: '80px',
                    height: '80px',
                    position: 'absolute',
                    top: '-40px',
                    left: '-40px',
                    transform: 'translateX(50%)',
                    transformOrigin: 'right',
                    opacity: '0.2',
                    transition: 'opacity 0.3s ease',
                  }"
                />
              </p>

              <!-- Star Rating using Images -->
              <div class="testimonial-rating">
                <img
                  v-for="star in testimonial.rating"
                  :key="star"
                  :src="getStarImagePath()"
                  alt="Star"
                  class="star-image"
                />
              </div>
            </div>
          </Slide>
        </Carousel>

        <!-- Custom Navigation Buttons -->
        <button class="carousel-nav-btn prev" @click="prevSlide">←</button>
        <button class="carousel-nav-btn next" @click="nextSlide">→</button>
      </div>

      <!-- Pagination Dots -->
      <div class="pagination">
        <span
          v-for="(testimonial, index) in testimonials"
          :key="index"
          class="dot"
          :class="{ active: index === currentSlideIndex }"
          @click="goToSlide(index)"
        ></span>
      </div>
    </div>

    <CheckAliv class="section-margin"></CheckAliv>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue3-carousel";
import BundleDetailsLong from "@/components/common/BundleDetailsLong.vue";
import FibrProductBanner from "@/components/common/FibrProductBanner.vue";
import FeatureDescription from "@/components/common/FeatureDescription.vue";
import SecondaryFeature from "@/components/fibr-products/SecondaryFeature.vue";
import ImageWithText from "@/components/common/ImageWithText.vue";
import CheckAliv from "@/components/common/CheckAliv.vue";
import RichText from "@/components/common/RichText.vue";
import entryId from "@/config/entryid.json";
import { getEntryFromArray } from "@/util/contentUtil.js";
import router from "@/router";

export default {
  name: "FibrInternet",
  inject: ["$contentful"],
  components: {
    FibrProductBanner,
    FeatureDescription,
    BundleDetailsLong,
    SecondaryFeature,
    ImageWithText,
    CheckAliv,
    Carousel,
    Slide,
    RichText,
  },
  data() {
    return {
      banner: {
        title: "",
        description: "",
        imageUrl: "",
      },
      features: [],
      secondaryFeature: {
        title: "",
        description: "",
        imageUrl: "",
      },
      addons: {
        title: "",
        description: "",
      },
      speed: {
        title: "",
        description: "",
      },
      fibrAddon1: {
        title: "",
        document: {},
        imageUrl: "",
      },
      fibrAddon2: {
        title: "",
        document: {},
        imageUrl: "",
      },
      bundles: [],
      viewAll: "",
      testimonials: [],
      starImagePath: "/star-image.png", // Image path for the star
      currentSlideIndex: 0, // Keep track of current slide index
    };
  },
  created() {
    this.loadPageContent();

    this.$contentful
      .getEntry(
        entryId.fibrInternetPage.fibrAddons.upgradedOpticalNetworkTerminal
      )
      .then((val) => {
        const fields = val.fields;
        this.fibrAddon1.title = fields.title;
        this.fibrAddon1.document = fields.description;
        this.fibrAddon1.imageUrl = fields.media?.fields?.file?.url;
      });

    this.$contentful
      .getEntry(entryId.fibrInternetPage.fibrAddons.meshWiFiExtender)
      .then((val) => {
        const fields = val.fields;
        this.fibrAddon2.title = fields.title;
        this.fibrAddon2.document = fields.description;
        this.fibrAddon2.imageUrl = fields.media?.fields?.file?.url;
      });
  },
  methods: {
    loadPageContent() {
      this.$contentful
        .getEntry(entryId.fibrInternetPage.fibrInternetPageContent)
        .then((val) => {
          const testimonialsList = val.fields.body[8].fields.listOfTestimonials;
          if (testimonialsList && testimonialsList.length) {
            testimonialsList.forEach((testimonial) => {
              this.$contentful.getEntry(testimonial.sys.id).then((res) => {
                this.testimonials.push({
                  title: res.fields.title,
                  occupation: res.fields.occupation,
                  rating: res.fields.rating,
                  isHeader: res.fields.testimonialHead,
                  insights: res.fields.insights,
                  avatarUrl: res.fields.avatar?.fields?.file?.url,
                });
              });
            });
          }

          const fields = val.fields.body;
          const bannerInfo = getEntryFromArray(
            fields,
            entryId.fibrInternetPage.fibrInternetFutureofHome
          );
          this.banner.title = bannerInfo.title;
          this.banner.description = bannerInfo.description;
          this.banner.imageUrl = bannerInfo.backgroundImage?.fields?.file?.url;
          this.viewAll = val.fields.url;

          this.loadFeatures(fields);
          this.loadSecondaryFeature(fields);
          this.loadAddons(fields);
          this.loadSpeed(fields);
        });
      this.loadBundles();
    },
    loadFeatures(fields) {
      const featureInfo1 = getEntryFromArray(
        fields,
        entryId.fibrInternetPage.futureReadyTech
      );
      const featureInfo2 = getEntryFromArray(
        fields,
        entryId.fibrInternetPage.fastInternetSpeed
      );
      const featureInfo3 = getEntryFromArray(
        fields,
        entryId.fibrInternetPage.oneGbpsDownloadSpeed
      );
      this.features.push({
        id: "1",
        title: featureInfo1.title,
        description: featureInfo1.description,
      });
      this.features.push({
        id: "2",
        title: featureInfo2.title,
        description: featureInfo2.description,
      });
      this.features.push({
        id: "3",
        title: featureInfo3.title,
        description: featureInfo3.description,
      });
    },
    loadSecondaryFeature(fields) {
      const secondaryFeatureInfo = getEntryFromArray(
        fields,
        entryId.fibrInternetPage.lifeAtAnySpeed
      );
      this.secondaryFeature.title = secondaryFeatureInfo.title;
      this.secondaryFeature.description = secondaryFeatureInfo.description;
      this.secondaryFeature.imageUrl =
        secondaryFeatureInfo.backgroundImage?.fields?.file?.url;
    },
    loadAddons(fields) {
      const fibrAddonsInfo = getEntryFromArray(
        fields,
        entryId.fibrInternetPage.fibrAddons.addons
      );
      this.addons.title = fibrAddonsInfo.title;
      this.addons.description = fibrAddonsInfo.description;
    },
    loadSpeed(fields) {
      const speedInfo = getEntryFromArray(
        fields,
        entryId.fibrInternetPage.speedYourHomeDeserves.fibrSpeed
      );
      this.speed.title = speedInfo.title;
      this.speed.description = speedInfo.description;
    },
    loadBundles() {
      const bundleIds = [
        entryId.fibrInternetPage.speedYourHomeDeserves["1p-1000"],
        entryId.fibrInternetPage.speedYourHomeDeserves["1p-500"],
        entryId.fibrInternetPage.speedYourHomeDeserves["1p-250"],
        entryId.fibrInternetPage.speedYourHomeDeserves["1p-100"],
      ]; // Adjust bundle IDs as needed
      bundleIds.forEach((id) => {
        this.$contentful.getEntry(id).then((val) => {
          const bundle = {
            name: val.fields.bundleName,
            description:
              val.fields.description?.content[0]?.content[0]?.value || "",
            price: val.fields.bundles[0]?.fields?.price || "",
            vat: val.fields.bundles[0]?.fields?.vat || "",
            details: val.fields.bundleDetails[0]?.fields?.benefits || [],
            id: val.sys.id,
            isOpen: false,
          };
          // Include extra details if applicable
          const extraDetails =
            val.fields.bundleDetails[0]?.fields
              ?.extraBundleDetailsIfApplicable || [];
          bundle.details.push(...extraDetails);

          this.bundles.push(bundle);
        });
      });
    },
    toggleDetails(bundle) {
      bundle.isOpen = !bundle.isOpen;
    },
    cardClicked(bundle) {
      router.push({
        name: "bundle-details",
        params: { bundle: encodeURI(bundle.name) },
      });
    },
    getStarImagePath() {
      return this.starImagePath; // Return the path of the star image
    },
    // Navigate to the previous slide
    prevSlide() {
      this.$refs.testimonialCarousel.prev();
      // Update the slide index manually
      const newIndex =
        this.currentSlideIndex === 0
          ? this.testimonials.length - 1
          : this.currentSlideIndex - 1;
      this.handleSlideChange(newIndex);
    },
    // Navigate to the next slide
    nextSlide() {
      this.$refs.testimonialCarousel.next();
      // Update the slide index manually
      const newIndex =
        this.currentSlideIndex === this.testimonials.length - 1
          ? 0
          : this.currentSlideIndex + 1;
      this.handleSlideChange(newIndex);
    },
    // Go to a specific slide when clicking on a dot
    goToSlide(index) {
      this.$refs.testimonialCarousel.slideTo(index);
      this.currentSlideIndex = index; // Ensure index is updated when clicked
    },
    // Handle slide change and update the current slide index
    handleSlideChange(index) {
      this.currentSlideIndex = index;
    },
  },
};
</script>

<style scoped lang="scss">
.fibr-addon-title {
  color: #000;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 32px;
}

.bundle-card {
  height: auto;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  display: block;
  position: relative;
  transition: height 800ms ease-in-out;
  overflow: hidden;
  padding-left: 32px;
  padding-right: 32px;
  text-align: center;
}

.bundle-details {
  margin-top: 30px;
  margin-bottom: 40px;
}

.toggle-button {
  color: #635890;
  border: none;
  border-radius: 16px;
  background-color: white;
  width: 100%;
}

.view-details-arrow {
  transform: rotate(180deg);
  margin-left: auto;
  margin-right: auto;
  display: block;
  transition: transform 400ms;
}

.view-details-arrow-open {
  transform: rotate(0deg);
  transition: transform 400ms;
}

.testimonial-slide {
  padding: 20px;
  background-color: #625691; /* Purple background */
  border-radius: 16px; /* Rounded corners */
  margin: 10px; /* Add gap between slides */
}

.testimonial-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto 20px;
}

.testimonial-title {
  font-weight: bold;
  font-size: 1.2rem;
  color: #fff;
}

.testimonial-occupation {
  color: #ddd; /* Lighter text for occupation */
  margin-bottom: 15px;
  font-size: 0.8rem;
}

.testimonial-insights {
  font-style: italic;
  margin-bottom: 15px;
  color: #f0f0f0; /* Lighten the text for readability */
  font-size: 0.8rem;
}

.testimonial-rating {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.star-image {
  width: 25px;
  height: 25px;
}

.carousel-container {
  position: relative;
}

/* Adjust slide width for smaller screens */
@media (max-width: 1024px) {
  .testimonial-slide {
    width: 80%; /* Reduce slide width to prevent overflow */
    margin: 0 auto; /* Center the slide */
  }
}

@media (max-width: 768px) {
  .testimonial-slide {
    width: 90%; /* Further reduce slide width on smaller screens */
    margin: 0 auto; /* Ensure the slide is centered */
  }

  /* Reposition carousel navigation buttons */
  .carousel-nav-btn {
    width: 30px; /* Smaller size for mobile */
    height: 30px; /* Smaller size for mobile */
  }

  .carousel-nav-btn.prev {
    left: 10px; /* Move inside the container */
  }

  .carousel-nav-btn.next {
    right: 10px; /* Move inside the container */
  }
}

.carousel-nav-btn {
  background-color: #f9f6f6;
  color: black;
  border: 1px solid #cbd5e1; /* slate-300 */
  padding: 6px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  border-radius: 50%; /* Fully rounded */
  box-shadow: 1px 1px 3px #fff3;
  width: 40px;
  height: 40px;
}

.carousel-nav-btn.prev {
  left: -50px; /* Position outside for desktop */
  @media (max-width: 1200px) {
    display: none;
  }
}

.carousel-nav-btn.next {
  right: -50px; /* Position outside for desktop */
  @media (max-width: 1200px) {
    display: none;
  }
}

.pagination {
  text-align: center;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #9fa7ad;
  border-radius: 50%;
  cursor: pointer;
}

.dot.active {
  background-color: #635890;
}

.available-text {
  color: #4f4f4f;
  text-align: center;
  font-size: $font-s;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.available-text a {
  color: black; /* Link color in black */
  font-weight: bold;
  text-decoration: underline;
}
.available-text a:hover {
  color: gray; /* Optional hover effect */
}
</style>
