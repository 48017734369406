<!-- App.vue -->
<template>


<div class="mainContainer">
  <div class="container">
    <header class="header">
      <img src="alivLand/hero.jpg" alt="Basketball Hoop" class="header-image" />
      <div class="header-content">
        <div class="countdown">
          <div class="countdown-item">
            <span class="countdown-box">{{ days }}</span>
            <span class="countdown-label">DAYS</span>
          </div>
          <div class="countdown-item">
            <span class="countdown-box">{{ hours }}</span>
            <span class="countdown-label">HOURS</span>
          </div>
          <div class="countdown-item">
            <span class="countdown-box">{{ minutes }}</span>
            <span class="countdown-label">MINUTES</span>
          </div>
          <div class="countdown-item">
            <span class="countdown-box">{{ seconds }}</span>
            <span class="countdown-label">SECONDS</span>
          </div>
        </div>
        <div class="promo-banner">
          <p>
            Sign up for <span class="highlight">ALIVFibr NBA League Pass</span> or add
            <span class="highlight">SportsX</span> to <br /> your ALIVFibr Infinite Bundle for a chance to win
          </p>
        </div>
      </div>
    </header>

    <section class="promo">
      <div class="promo-content">
        <img src="alivLand/basketball.png" alt="Basketball Icon" class="promo-icon left-icon" />
        <div class="promo-text">
          <h2>Score an all-expense paid trip for two (2) to attend the NBA Finals 2025 Games</h2>
          <p>Roundtrip airfare + complimentary accommodations + exclusive game day perks</p>
        </div>
        <img src="alivLand/basketball.png" alt="Basketball Icon" class="promo-icon right-icon" />
      </div>
    </section>

    <section class="offer-section">
      <h2 class="offer-title">Eligible Packages</h2>
      <p class="offer-description">
        Existing ALIVFibr customers are already subscribed to either NBA League Pass or <br />
        SportsX are automatically entered to win.
      </p>
      <div class="offer-container">
        <div class="offer-card">
          <div class="logo-wrapper">
            <img src="alivLand/NBA-League-Pass-logo.png" alt="NBA League Pass Logo" />
          </div>
          <p class="card-text">Add NBA League Pass to an ALIVFibr TV package for</p>
          <p class="price-tag">$109.99</p>
        </div>
        <div class="offer-card">
          <div class="logo-wrapper">
            <img src="alivLand/SportX.png" alt="SportsX Logo" />
          </div>
          <p class="card-text">Sign-up for any ALIV Triple play bundle and get SportsX add-on for</p>
          <p class="price-tag">$5</p>
        </div>
      </div>
    </section>

    <section class="activation">
      <iframe
        aria-label="Activate Today for a Chance To Win!"
        frameborder="0"
        style="height: 800px; width: 75%; border: none;"
        src="https://forms.zohopublic.eu/cablebahamas/form/ActivateTodayforaChanceToWin/formperma/p9bFUWKbhYNgl8d6Zsq_iRyS0TvmPHfvxOKiZNRfHq8"
      ></iframe>
    </section>

    <footer>
      <div class="footer-logo">
        <img src="alivLand/AlivFibr-Colour-White.png" alt="Logo" />
      </div>
      <div class="social-icons">
        <a href="https://www.facebook.com/WeAreALIV/" target="_blank">
          <img src="alivLand/facebook-white.png" alt="Facebook Icon" class="social-icon" />
        </a>
        <a href="https://www.instagram.com/wearealiv/" target="_blank">
          <img src="alivLand/instagram-white.png" alt="Instagram Icon" class="social-icon" />
        </a>
      </div>
      <p>Copyright © 2025 | <a href="/alivLand/NBAFinals2025-AllAccessSweepstakes-Terms&Conditions.pdf" target="_blank"><span style="text-decoration: underline; color: white">Terms and conditions</span></a></p>
    </footer>

    
  </div>
  <div class="footer-line">
      <img src="alivLand/aliv-motif.jpg" alt="Multicolor Line" class="line-img" />
    </div>
</div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      targetDate: new Date('May 16, 2025 23:59:59').getTime(),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },
  mounted() {
    this.startCountdown();
  },
  methods: {
    startCountdown() {
      setInterval(() => {
        const now = new Date().getTime();
        const timeRemaining = this.targetDate - now;

        this.days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

        if (timeRemaining < 0) {
          clearInterval(this.countdown);
          this.$el.querySelector('.countdown').innerHTML = '<p>¡La promoción ha terminado!</p>';
        }

        // Ensure two-digit display
        this.days = this.days < 10 ? '0' + this.days : this.days;
        this.hours = this.hours < 10 ? '0' + this.hours : this.hours;
        this.minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes;
        this.seconds = this.seconds < 10 ? '0' + this.seconds : this.seconds;
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.mainContainer {
  margin: 0;
  font-family: Arial, sans-serif;
 background: url('/public/alivLand/bg-shapes2.png') no-repeat center center;
  background-color: #645d9c;
  background-size: cover;
  color: #fff;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.header {
  position: relative;
  text-align: center;
  padding: 0;
}

.header-image {
  width: 100%;
  height: auto;
  display: block;
}

.header-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.promo-banner {
  padding: 15px 20px;
  text-align: center;
}

.promo-banner p {
  font-size: 1.1em;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  margin: 0;
  line-height: 1.4;
}

.highlight {
  color: #ffd700;
  font-weight: bold;
}

@media (max-width: 768px) {
  .promo-banner p {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .promo-banner {
    padding: 10px 15px;
  }
  .promo-banner p {
    font-size: 0.9em;
  }
}

.countdown {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 42%;
}

.countdown-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown-box {
  background: linear-gradient(45deg, #faf9fa, #fbfafb);
  color: #645d9c;
  padding: 15px;
  margin: 0;
  border-radius: 10%;
  font-size: 72px;
  width: 85px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.countdown-label {
  font-size: 14px;
  margin-top: 5px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.promo {
  padding: 20px;
  text-align: center;
  background: #ffd700;
}

.promo-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.promo-icon {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
}

.left-icon {
  order: -1;
}

.right-icon {
  order: 1;
}

.promo-text {
  flex: 1;
  max-width: 1000px;
}

.promo h2 {
  font-size: 3em;
  font-weight: bold;
  color: #645d9c;
  margin-bottom: 10px;
  line-height: 1.2;
}

.promo p {
  font-size: 1.5em;
  font-weight: bold;
  color: #645d9c;
  margin: 0;
}

@media (max-width: 768px) {
  .promo-content {
    flex-direction: column;
    gap: 10px;
  }
  .promo-icon {
    width: 60px;
    height: 60px;
  }
  .left-icon,
  .right-icon {
    order: 0;
  }
  .promo h2 {
    font-size: 1.5em;
  }
  .promo p {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .promo {
    padding: 15px;
  }
  .promo-icon {
    width: 30px;
    height: 30px;
  }
  .promo h2 {
    font-size: 1.2em;
  }
  .promo p {
    font-size: 0.8em;
  }
}

.offer-section {
  text-align: center;
  padding: 40px 20px;
  background: #000;
  color: #000;
}

.offer-title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}

.offer-description {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 30px;
  color: #fff;
}

.offer-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.offer-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  width: 350px;
  text-align: center;
}

.logo-wrapper {
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 5px 20px;
  display: inline-block;
  margin-bottom: 15px;
}

.logo-wrapper img {
  height: 30px;
  width: auto;
}

.card-text {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 25px;
  margin-bottom: 10px;
  color: #645d9c;
}

.price-tag {
  font-size: 3em;
  font-weight: bold;
  color: #645d9c;
}

.activation {
  padding: 20px;
  text-align: center;
  background: #070707;
}

footer {
  text-align: center;
  padding: 20px;
  background: #000;
}

.footer-logo img {
  height: 50px;
  width: auto;
}

.social-icons a img {
  height: 20px;
  width: auto;
  margin-right: 10px;
}

.footer-line .line-img {
  width: 100%;
  height: auto;
  display: block;
}
</style>