<template>
  <div class="w-100">
    <FibrProductBanner
      :imageUrl="banner.imageUrl"
      :title="banner.title"
      :description="banner.description"
      :ctaLabel="banner.buttonText"
    ></FibrProductBanner>
    <div class="horizontal-page-margin vertical-page-margin">
      <div class="fibr-cards">
        <div class="row">
          <div
            class="col-md-4 col-sm-6 col-12"
            v-for="feature in features"
            :key="feature.id"
          >
            <TopBorderFibrCard
              :title="feature.title"
              :description="feature.description"
              :icon-tvUrl="feature.iconUrl"
            >
            </TopBorderFibrCard>
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 60px;
        "
      >
        <a
          href="#nfl-section"
          style="
            background-color: #635890;
            border-radius: 16px;
            color: white;
            font-weight: 700;
            padding: 10px 10px;
            text-decoration: none;
            box-shadow: 1px 1px 12px gray;
          "
          >Sign up for seasonal sports add-on</a
        >
      </div>
      <div class="section-margin-large">
        <div class="heading-dark">{{ appsTitle }}</div>
        <div class="row">
          <div
            class="col-md-6 col-lg-3 col-sm-6 col-12"
            v-for="app in apps"
            :key="app.id"
          >
            <div class="app-card hover-effect">
              <div
                class="div-icon-image"
                :style="{ backgroundImage: `url(${app.iconUrl})` }"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <FeatureDescription
        class="section-margin-large"
        :title="planFeatures.title"
        :description="planFeatures.description"
      ></FeatureDescription>
      <div class="available-text">
        Aliv Fibr is available only for New Providence customers.<br>
        View service availability in your area – explore the map <a href="/map.pdf" target="_blank">here</a>.
      </div>

      <div class="available-text">
      To view a list of our plans and services, please click <a href="/bundles.pdf" target="_blank">here</a>.
    </div>
      <div class="section-margin-small">
        <div class="fcontainer">
          <div
            v-for="(plan, index) in [localTv]"
            :key="index"
            :class="['fitem', { 'expanded-card': plan.isOpen }]"
            :style="plan.isOpen ? { height: 'auto' } : { height: '100%' }"
            class="flex-column justify-content-center align-items-stretch text-sm-center py-3 px-4 rounded-3 shadow-lg fitem"
          >
            <h4
              v-if="plan.heading"
              class="fw-semibold"
              :style="{ color: '#635890' }"
            >
              {{ plan.heading }}
            </h4>
            <p :style="{ fontSize: '14px' }">{{ plan.description }}</p>
            <div
              style="display: flex; width: 100%; align-items: center; gap: 10px"
            >
              <img
                v-for="channel in plan.channels.slice(0, 4)"
                :key="channel.title"
                :src="channel.logo"
                :alt="channel.title"
                :title="channel.title"
                style="width: 48px; height: auto"
              />
            </div>
            <button
              v-if="plan.channels.length > 4"
              @click="() => showModal(plan)"
              class="see-more-button"
            >
              See More
            </button>
            <p
              :style="{ color: '#635890', fontSize: '30px', marginBottom: '0' }"
              class="fw-bolder"
            >
              {{ plan.price }}
            </p>
            <span
              :style="{
                color: '#635890',
                marginBottom: '8px',
                fontSize: '15px',
              }"
              >{{ plan.vat }}</span
            ><br />
            <button
              @click="() => cardClicked(plan)"
              type="button"
              :style="{
                marginBottom: '9px',
                marginTop: '9px',
                backgroundColor: '#635890',
                border: 'none',
                color: '#fff',
                padding: '8px 0px',
                borderRadius: '15px',
                fontWeight: '700',
                width: '100%',
              }"
            >
              Get Started
            </button>
            <div v-show="plan.isOpen" class="mt-3">
              <div class="title">
                <div
                  class="icon-tv"
                  :style="{
                    backgroundImage: `url(//images.ctfassets.net/u7e5kg3vu9je/4GXpsa2jstVeIHZ6TwUKJ4/783c2cf142092f9daf029e3a616e760c/Coloured_TV_Icon.png)`,
                  }"
                ></div>
                <div>{{ plan.heading }}</div>
              </div>
              <div :class="{ 'section-margin-small': index > 0 }">
                <p
                  v-for="detail in plan?.details"
                  class="benefits"
                  :key="detail"
                  :style="{ marginBottom: 0, marginTop: 0 }"
                >
                  {{ detail }}
                </p>
              </div>
            </div>
            <button
              @click="() => toggleDetails(plan)"
              type="button"
              class="toggle-button mt-3"
            >
              {{ plan.isOpen ? "See less" : "View details" }} <br />
              <img
                class="view-details-arrow"
                :class="{ 'view-details-arrow-open': plan.isOpen }"
                :src="require('@/assets/icons/purple_arrow.svg')"
              />
            </button>
          </div>
        </div>

        <div
          class="flex link-style my-5"
          style="
            width: 100% !important;
            justify-content: center;
            align-items: center;
            display: flex;
          "
        >
          <a
            :href="this.url"
            class="text-white py-2 px-4 rounded-4 text-decoration-none"
            style="background-color: #635890; font-weight: 900"
            >Looking for more ALIVFibr TV plans?
          </a>
        </div>
        <ImageWithText
          class="section-margin"
          :imageUrl="fibrTVSection1.imageUrl"
          :leftAlignImage="true"
        >
          <div class="fibr-tv-section-title">{{ fibrTVSection1.title }}</div>
          <RichText
            class="fibr-tv-section-description"
            :document="fibrTVSection1.description"
          ></RichText>
        </ImageWithText>
        <ImageWithText
          class="section-margin"
          :imageUrl="fibrTVSection2.imageUrl"
          :leftAlignImage="false"
        >
          <div class="fibr-tv-section-title">{{ fibrTVSection2.title }}</div>
          <RichText
            class="fibr-tv-section-description"
            :document="fibrTVSection2.description"
          ></RichText>
        </ImageWithText>
        <div id="nfl-section" style="height: 60px"></div>
        <div
          style="
            height: 80dvh;
            background-color: #635890;
            margin-top: 60px;
            margin-bottom: 60px;
            border-radius: 20px;
          "
        >
          <iframe
            :src="'https://forms.zohopublic.eu/cablebahamas/form/ALIVFibrNFLSundayTicketSignup/formperma/rDuHwCP2tGCUtxLVnhoJvew3_4rlSEmIODBbmGsgWBQ'"
            :width="'100%'"
            :height="'100%'"
            :title="NFLSundayTicketSignup"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
        <ImageWithText
          class="section-margin"
          :imageUrl="fibrTVSection3.imageUrl"
          :leftAlignImage="true"
          :isVideo="true"
        >
          <div class="fibr-tv-section-title">{{ fibrTVSection3.title }}</div>
          <RichText
            class="fibr-tv-section-description"
            :document="fibrTVSection3.description"
          ></RichText>
        </ImageWithText>
        <!-- addon section -->
        <div
          class="w-full text-center"
          style="font-size: xx-large; margin: 60px 0 20px 0"
        >
          <p>
            {{ addonHeading.title }}
            <strong>{{ addonHeading.strongText }}</strong>
          </p>
        </div>
        <div>
          <div class="tabs">
            <div
              v-for="(addon, index) in addons"
              :key="index"
              :class="['tab', { active: selectedAddonIndex === index }]"
              @click="selectAddon(index)"
            >
              {{ addon.category }}
            </div>
          </div>
          <div class="tab-content">
            <div v-if="selectedAddonIndex !== null">
              <!-- Single Addon View -->
              <div
                v-if="addons[selectedAddonIndex].addons.length === 1"
                class="single-addon"
              >
                <div
                  v-for="addon in addons[selectedAddonIndex].addons"
                  :key="addon.title"
                >
                  <h3 style="width: 100%; text-align: center">
                    {{ addon.title }}
                  </h3>
                  <p style="width: 100%; text-align: center">
                    {{ addon.description }}
                  </p>
                  <div class="channels">
                    <div
                      v-for="channel in addon.channels.slice(0, 5)"
                      :key="channel.title"
                      class="channel-card"
                    >
                      <img :src="channel.logo" :alt="channel.title" />
                      <span>{{ channel.title }}</span>
                    </div>
                  </div>
                  <h2
                    style="
                      width: 100%;
                      text-align: center;
                      color: #635890;
                      margin: 20px 0 20px 0;
                    "
                  >
                    {{ addon.price }}
                  </h2>
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <button
                      @click="() => showAddonModal(addon)"
                      type="button"
                      class="see-more-button"
                    >
                      See Details
                    </button>
                  </div>
                </div>
              </div>
              <!-- Multiple Addons View -->
              <div v-else class="multiple-addons">
                <div
                  v-for="addon in addons[selectedAddonIndex].addons"
                  :key="addon.title"
                  class="addon-row"
                >
                  <div class="addon-logo">
                    <img :src="addon.channels[0].logo" :alt="addon.title" />
                    <div class="channel-titles">
                      <span
                        v-for="channel in addon.channels.slice(0, 3)"
                        :key="channel.title"
                      >
                        {{ channel.title }}
                      </span>
                    </div>
                  </div>
                  <div class="addon-description">{{ addon.description }}</div>
                  <div class="addon-price">{{ addon.price }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CheckAliv class="section-margin"></CheckAliv>
      </div>
      <div v-if="loading">Loading...</div>

      <!-- Modal for Showing All Channels -->
      <div v-if="isModalVisible" class="modal">
        <div class="modal-overlay" @click="hideModal"></div>
        <div class="modal-content">
          <h3>{{ selectedPlan.heading }} Channels</h3>
          <div class="grid-view">
            <div
              class="channel-card"
              v-for="channel in selectedPlan.channels"
              :key="channel.title"
            >
              <img :src="channel.logo" :alt="channel.title" />
              <span>{{ channel.title }}</span>
            </div>
          </div>
          <button @click="hideModal" class="close-modal">Close</button>
        </div>
      </div>

      <!-- Modal for Addon Details -->
      <div v-if="isAddonModalVisible" class="modal">
        <div class="modal-overlay" @click="hideAddonModal"></div>
        <div class="modal-content">
          <h3>{{ selectedAddon.title }} Details</h3>
          <p>{{ selectedAddon.description }}</p>
          <div class="grid-view" style="aspect-ratio: 1/4">
            <div
              class="channel-card"
              v-for="channel in selectedAddon.channels"
              :key="channel.title"
            >
              <img :src="channel.logo" :alt="channel.title" />
              <span>{{ channel.title }}</span>
            </div>
          </div>
          <h2
            style="
              width: 100%;
              text-align: center;
              color: #635890;
              margin: 20px 0 20px 0;
            "
          >
            {{ selectedAddon.price }}
          </h2>
          <button @click="hideAddonModal" class="close-modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FibrProductBanner from "@/components/common/FibrProductBanner.vue";
import TopBorderFibrCard from "@/components/common/TopBorderFibrCard.vue";
import FeatureDescription from "@/components/common/FeatureDescription.vue";
import ImageWithText from "@/components/common/ImageWithText.vue";
import RichText from "@/components/common/RichText.vue";
import CheckAliv from "@/components/common/CheckAliv.vue";
import entryId from "@/config/entryid.json";
import { getEntryFromArray } from "@/util/contentUtil.js";
import router from "@/router";

export default {
  name: "FibrTV",
  inject: ["$contentful"],
  components: {
    FibrProductBanner,
    TopBorderFibrCard,
    FeatureDescription,
    ImageWithText,
    RichText,
    CheckAliv,
  },
  data() {
    return {
      loading: true, // Loading state
      banner: {
        imageUrl: "",
        title: "",
        description: "",
        buttonText: "Sign up",
      },
      features: [],
      appsTitle: "",
      apps: [],
      planFeatures: {
        title: "",
        description: "",
      },
      fibrTVSection1: {
        title: "",
        description: {},
        imageUrl: "",
      },
      fibrTVSection2: {
        title: "",
        description: {},
        imageUrl: "",
      },
      fibrTVSection3: {
        title: "",
        description: {},
        imageUrl: "",
      },
      proTv: {
        heading: "",
        description: "",
        channels: [],
        price: "",
        vat: "",
        isOpen: false,
      },
      primeTv: {
        heading: "",
        description: "",
        channels: [],
        price: "",
        vat: "",
        isOpen: false,
      },
      localTv: {
        heading: "",
        description: "",
        channels: [],
        price: "",
        vat: "",
        isOpen: false,
      },
      addonHeading: {
        title: "",
        strongText: "",
      },
      addons: [],
      selectedAddonIndex: null,
      isModalVisible: false,
      selectedPlan: null,
      isAddonModalVisible: false,
      selectedAddon: null,
    };
  },
  created() {
    this.$contentful
      .getEntry(entryId.fibrTvPage.fibrTvPageContent)
      .then((val) => {
        this.url = val.fields.url;
        const fields = val.fields.body;
        this.addonHeading = fields[9].fields;
        // fetch addons with theirs channels
        let addonlist = fields[10].fields.addons;
        let parsedList = [];
        addonlist.map((addon) =>
          this.$contentful?.getEntry(addon.sys.id).then((data) => {
            let parsedData = {
              category: "",
              addons: [],
            };
            parsedData.category = data.fields.categoryName;
            data.fields.addons.forEach((addon) => {
              let channelslist = [];
              addon.fields.channels.forEach((channel) => {
                this.$contentful.getEntry(channel.sys.id).then((entry) => {
                  channelslist.push({
                    title: entry.fields.channelTitle,
                    logo: entry.fields.channelLogo?.fields?.file?.url,
                  });
                });
              });
              parsedData.addons.push({
                title: addon.fields.title,
                description: addon.fields.description,
                price: addon.fields.price,
                channels: channelslist,
              });
            });
            parsedList.push(parsedData);
            this.selectedAddonIndex = 0; // Select the first tab by default
          })
        );
        this.addons = parsedList;
        const bannerData = getEntryFromArray(fields, entryId.fibrTvPage.fibrTv);
        this.banner.imageUrl = bannerData?.backgroundImage?.fields?.file?.url;
        this.banner.title = bannerData.title;
        this.banner.description = bannerData.description;

        const endlessEntertainment = getEntryFromArray(
          fields,
          entryId.fibrTvPage.endlessEntertainment
        );
        this.features.push({
          id: 1,
          title: endlessEntertainment.title,
          description: endlessEntertainment.description,
          iconUrl: endlessEntertainment?.icon?.fields?.file?.url,
        });

        const personalizedTv = getEntryFromArray(
          fields,
          entryId.fibrTvPage.personalizedTv
        );
        this.features.push({
          id: 2,
          title: personalizedTv.title,
          description: personalizedTv.description,
          iconUrl: personalizedTv?.icon?.fields?.file?.url,
        });

        const premiumSports = getEntryFromArray(
          fields,
          entryId.fibrTvPage.premiumSports
        );
        this.features.push({
          id: 3,
          title: premiumSports.title,
          description: premiumSports.description,
          iconUrl: premiumSports?.icon?.fields?.file?.url,
        });

        const fibrTvAppsContent = getEntryFromArray(
          fields,
          entryId.fibrTvPage.fibrTvApps.fibrTvAppsContent
        );
        this.appsTitle = fibrTvAppsContent.title;

        const planFeaturesContent = getEntryFromArray(
          fields,
          entryId.fibrTvPage.planFeatures.planFeaturesContent
        );
        this.planFeatures.title = planFeaturesContent.title;
        this.planFeatures.description = planFeaturesContent.description;

        const futureOfTv = getEntryFromArray(
          fields,
          entryId.fibrTvPage.futureOfTv
        );
        this.fibrTVSection1.title = futureOfTv.title;
        this.fibrTVSection1.description = futureOfTv.descriptionRt;
        this.fibrTVSection1.imageUrl = futureOfTv?.image?.fields?.file?.url;

        const sportsWithFibr = getEntryFromArray(
          fields,
          entryId.fibrTvPage.sportsWithFibr
        );
        this.fibrTVSection2.title = sportsWithFibr.title;
        this.fibrTVSection2.description = sportsWithFibr.descriptionRt;
        this.fibrTVSection2.imageUrl = sportsWithFibr?.image?.fields?.file?.url;

        const setupHelp = getEntryFromArray(
          fields,
          entryId.fibrTvPage.setupHelp
        );
        this.fibrTVSection3.title = setupHelp.title;
        this.fibrTVSection3.description = setupHelp.descriptionRt;
        this.fibrTVSection3.imageUrl = setupHelp?.image?.fields?.file?.url;

        // Fetch apps
        this.fetchApps();

        let plans = ["localTv", "primeTv", "proTv"];
        this.$contentful
          .getEntry(entryId.fibrTvPage.planFeatures.AlivFibrPrimeTV)
          .then((val) => {
            this.fibrBundles = val.fields.bundles;
            console.log(this.fibrBundles);
            this.fibrBundles.forEach((bundle, i) => {
              this[plans[i]] = {
                ...bundle.fields,
                id: bundle.sys.id,
                channels: [],
                isOpen: false,
              };

              let channels = [];
              bundle.fields.channels.forEach((channel) => {
                this.$contentful.getEntry(channel?.sys?.id).then((entry) => {
                  channels.push({
                    title: entry.fields.channelTitle,
                    logo: entry.fields.channelLogo?.fields?.file?.url,
                  });
                  this[plans[i]].channels = [...channels];
                });
              });
            });
            this.loading = false;
          });
      });
  },
  methods: {
    showModal(plan) {
      this.selectedPlan = plan;
      this.isModalVisible = true;
    },
    hideModal() {
      this.isModalVisible = false;
      this.selectedPlan = null;
    },
    showAddonModal(addon) {
      this.selectedAddon = addon;
      this.isAddonModalVisible = true;
    },
    hideAddonModal() {
      this.isAddonModalVisible = false;
      this.selectedAddon = null;
    },
    fetchApps() {
      const appIds = [
        entryId.fibrTvPage.fibrTvApps.netflix,
        entryId.fibrTvPage.fibrTvApps.disneyPlus,
        entryId.fibrTvPage.fibrTvApps.espnPlus,
        entryId.fibrTvPage.fibrTvApps.hboMax,
      ];
      appIds.forEach((appId, index) => {
        this.$contentful.getEntry(appId).then((val) => {
          this.apps.push({
            id: index + 1,
            iconUrl: val?.fields?.image?.fields?.file?.url,
          });
        });
      });
    },
    cardClicked(bundle) {
      router.push({
        name: "bundle-details",
        params: { bundle: encodeURI(bundle.fullName) },
      });
    },
    toggleDetails(bundle) {
      bundle.isOpen = !bundle.isOpen;
    },
    selectAddon(index) {
      this.selectedAddonIndex = index;
    },
  },
};
</script>

<style scoped lang="scss">
.see-more-button {
  background-color: #635890;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  margin: 10px 0 10px 0;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 1001;
  width: 80%;
  max-width: 600px;
  max-height: 80%;
  overflow-y: auto;
}

.grid-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  margin-top: 20px;
}

.channel-card {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.channel-card img {
  max-width: 48px;
  max-height: 48px;
  object-fit: contain;
}

.close-modal {
  margin-top: 20px;
  background-color: #635890;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.heading-dark {
  margin-bottom: 32px;
}

@media only screen and (min-width: 768px) {
  .fibr-cards {
    margin-top: -100px;
  }
}

.fcontainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;

  @media (max-width: 950px) {
    flex-direction: column;
    align-items: center !important;
  }
}

.fitem {
  flex: 1 1 25%;
  max-width: 25%;
  font-size: 12px;
  border: none;
  margin: 20px;

  @media (max-width: 950px) {
    flex: 1 1 100%;
    max-width: 300px !important;
    margin-bottom: 20px;
  }
}

.app-card {
  height: 222px;
  border-radius: $border-radius;
  border: 1px solid #f2f2f2;
  background: white;
  box-shadow:
    0px 7.925px 10.054px 0px rgba(0, 0, 0, 0.05),
    0px 21.913px 27.798px 0px rgba(0, 0, 0, 0.03),
    0px 52.757px 66.926px 0px rgba(0, 0, 0, 0.03),
    0px 175px 222px 0px rgba(0, 0, 0, 0.02);
}

.div-icon-image {
  height: 125px;
  width: 230px;
  position: relative;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
}

.card-container {
  position: relative;
}

.middle-card {
  z-index: 1;
  overflow: hidden;
}

.side-card {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  margin: auto;
}

@media (max-width: 767px) {
  .side-card {
    display: none;
  }
}

.fibr-tv-section-title {
  color: #000;
  font-size: $font-l;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 32px;
}

.fibr-tv-section-description {
  color: #4f4f4f;
  font-size: $font-s;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}

.icon-tv {
  display: inline-block;
  width: 34px !important;
  height: 28px !important;
  background-size: cover;
}

.bundle-details {
  margin-top: 30px;
  margin-bottom: 40px;
}

.bundle-card {
  height: auto;
  border: 1px solid #e0e0e0;
  border-radius: $border-radius;
  display: block;
  position: relative;
  transition: height 800ms ease-in-out;
  overflow: hidden;
  padding-left: 32px;
  padding-right: 32px;
  text-align: center;

  &-expanded {
    height: auto;
    transition: height 800ms ease-in-out;
  }
}

.toggle-button {
  color: #635890;
  border: none;
  border-radius: 16px;
  background-color: white;
  width: 100%;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.view-details-arrow {
  transform: rotate(180deg);
  margin-left: auto;
  margin-right: auto;
  display: block;
  transition: transform 400ms;

  &-open {
    transform: rotate(0deg);
    transition: transform 400ms;
  }
}

.icon-tv {
  height: 20px;
  width: 20px;
  margin-right: 8px;
  margin-top: -2px;
}

.title {
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-items: center;
  justify-content: center;
}

.benefits {
  color: #4f4f4f;
  text-align: center;
  font-size: $font-xxs;
  font-style: normal;
  font-weight: 450;
}

.tabs {
  display: flex;
  border-bottom: 2px solid #e0e0e0;
  margin-bottom: 20px;
  flex-wrap: wrap;

  .tab {
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    border-bottom: 2px solid transparent;
    color: #635890;
    font-weight: bold;

    &.active {
      border-bottom: 2px solid #635890;
      color: #635890;
    }

    &:hover {
      color: #635890;
    }
  }
}

.tab-content {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;

  .channels {
    display: flex;
    flex-wrap: wrap;

    .channel-card {
      text-align: center;
      flex: 1 1 100px;
      margin: 10px;

      img {
        max-width: 48px;
        max-height: 48px;
        object-fit: contain;
      }
    }
  }
}
.multiple-addons {
  .addon-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    background-color: #f9f9f9;
  }

  .addon-logo {
    flex: 1;
    text-align: center;
    margin-right: 20px;
    img {
      max-width: 100px;
      max-height: 100px;
    }
    .channel-titles {
      margin-top: 10px;
      span {
        display: block;
        font-size: 14px;
      }
    }
  }

  .addon-description {
    flex: 2;
    font-size: 16px;
    text-align: center;
    margin: auto 0;
  }

  .addon-price {
    flex: 1;
    font-size: 18px;
    font-weight: bold;
    color: #635890;
    text-align: center;
    margin: auto 0;
  }
}

// Responsive adjustments for mobile view
@media only screen and (max-width: 768px) {
  .addon-row {
    flex-direction: column;
    text-align: center;
  }

  .addon-logo,
  .addon-description,
  .addon-price {
    margin-bottom: 10px;
  }
}
.available-text {
  color: #4f4f4f;
  text-align: center;
  font-size: $font-s;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.available-text a {
  color: black; /* Link color in black */
  font-weight: bold;
  text-decoration: underline;
}
.available-text a:hover {
  color: gray; /* Optional hover effect */
}
</style>
